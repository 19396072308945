import { ProgressBar } from "@basaltbytes/ui/progress-bar.tsx";
import {
  NonFlashOfWrongThemeEls,
  Theme,
  ThemeProvider,
  useTheme,
} from "@basaltbytes/ui/theme-provider.tsx";
import { Toaster } from "@basaltbytes/ui/toaster.tsx";
import {
  json,
  type LinksFunction,
  type LoaderFunctionArgs,
  type MetaFunction,
} from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
} from "@remix-run/react";
import { withSentry } from "@sentry/remix";
import { clsx } from "clsx";
import { useTranslation } from "react-i18next";
import { useChangeLanguage } from "remix-i18next/react";
import { ClientOnly } from "remix-utils/client-only";
import { ExternalScripts } from "remix-utils/external-scripts";
import { getClientLocales } from "remix-utils/locales/server";

import i18next from "~/i18next.server.ts";
import { app } from "~/settings.ts";
import { getEnv } from "./env.server.ts";
import { getUser } from "./storage/auth.server.ts";
import { getThemeSession } from "./storage/theme.server.ts";
import fontStylesheet from "./styles/fonts.css?url";
import reactZoomStylesheetUrl from "./styles/react-zoom.css?url";
import tailwindStylesheetUrl from "./styles/tailwind.css?url";
import { GeneralErrorBoundary } from "./ui/error-boundary.tsx";
import { ClientHintCheck, getHints } from "./utils/client-hints.tsx";
import { ExternalHeadScripts } from "./utils/external-scripts.tsx";
import { getDomainUrl } from "./utils/misc.ts";
import { useNonce } from "./utils/nonce-provider.ts";

export const links: LinksFunction = () => {
  return [
    { rel: "preload", href: fontStylesheet, as: "style" },
    // { rel: "preload", href: reactZoomStylesheetUrl, as: "style" },
    { rel: "preconnect", href: "https://res.cloudinary.com", as: "link" },
    { rel: "dns-prefetch", href: "https://res.cloudinary.com", as: "link" },
    { rel: "preconnect", href: "https://fonts.googleapis.com", as: "link" },
    {
      rel: "preconnect",
      href: "https://fonts.gstatic.com",
      as: "link",
      crossOrigin: "anonymous",
    } as const,
    { rel: "apple-touch-icon", href: "/favicons/apple-touch-icon.png" },
    {
      rel: "manifest",
      href: "/manifest.webmanifest",
      crossOrigin: "use-credentials",
    } as const,
    { rel: "stylesheet", href: fontStylesheet },
    { rel: "stylesheet", href: tailwindStylesheetUrl },
    { rel: "stylesheet", href: reactZoomStylesheetUrl },
  ].filter(Boolean);
};

export let handle = {
  // In the handle export, we can add a i18n key with namespaces our route
  // will need to load. This key can be a single string or an array of strings.
  // TIP: In most cases, you should set this to your defaultNS from your i18n config
  // or if you did not set one, set it to the i18next default namespace "translation"
  i18n: ["common", "admin", "dashboard"],
};

export const meta: MetaFunction = () => [
  { title: `${app.title} - ${app.description}` },
];

export async function loader({ request }: LoaderFunctionArgs) {
  const [themeSession, locale, user] = await Promise.all([
    getThemeSession(request),
    i18next.getLocale(request),
    getUser(request),
  ]);

  const locales = getClientLocales(request);
  return json({
    user,
    requestInfo: {
      hints: getHints(request),
      origin: getDomainUrl(request),
      path: new URL(request.url).pathname,
      // userPrefs: {
      //   theme: getTheme(request),
      // },
    },
    locales,
    theme: themeSession.getTheme(),
    ENV: getEnv(),
    locale,
  });
}

function Document({
  children,
  nonce,
  env = {},
  dataTheme,
  locale,
  allowIndexing = true,
}: {
  children: React.ReactNode;
  nonce: string;
  env?: Record<string, string>;
  dataTheme: Theme;
  locale: string;
  allowIndexing?: boolean;
}) {
  const [theme] = useTheme();
  let { i18n } = useTranslation();
  // This hook will change the i18n instance language to the current locale
  // detected by the loader, this way, when we do something to change the
  // language, this locale will change and i18next will load the correct
  // translation files
  useChangeLanguage(locale);

  return (
    <html
      lang={locale}
      dir={i18n.dir()}
      className={clsx("h-full", theme)}
      suppressHydrationWarning
    >
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        {allowIndexing ? null : (
          <meta name="robots" content="noindex, nofollow" />
        )}
        <Meta />
        <Links />
        <ClientHintCheck nonce={nonce} />
        <ExternalHeadScripts nonce={nonce} />
        <NonFlashOfWrongThemeEls ssrTheme={Boolean(dataTheme)} />
      </head>
      <body
        className="h-full min-h-full bg-sidebar text-foreground"
        suppressHydrationWarning
      >
        {children}
        <script
          nonce={nonce}
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`,
          }}
        />
        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
        <ExternalScripts />
        <ClientOnly>{() => <Toaster />}</ClientOnly>
      </body>
    </html>
  );
}

function SimpleDocument({
  children,
  nonce,
  env = {},
  dataTheme,
  locale,
}: {
  children: React.ReactNode;
  nonce: string;
  env?: Record<string, string>;
  dataTheme: Theme;
  locale: string;
}) {
  let { i18n } = useTranslation();
  // This hook will change the i18n instance language to the current locale
  // detected by the loader, this way, when we do something to change the
  // language, this locale will change and i18next will load the correct
  // translation files
  useChangeLanguage(locale);

  return (
    <html
      lang={locale}
      dir={i18n.dir()}
      className={clsx("h-full", dataTheme)}
      suppressHydrationWarning
    >
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
        <ClientHintCheck nonce={nonce} />
      </head>
      <body
        className="h-full min-h-full bg-background text-foreground"
        suppressHydrationWarning
      >
        {children}
        <script
          nonce={nonce}
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`,
          }}
        />
        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
        <ExternalScripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  // the nonce doesn't rely on the loader so we can access that
  const nonce = useNonce();

  // NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
  // likely failed to run so we have to do the best we can.
  // We could probably do better than this (it's possible the loader did run).
  // This would require a change in Remix.

  // Just make sure your root route never errors out and you'll always be able
  // to give the user a better UX.

  return (
    <SimpleDocument nonce={nonce} dataTheme={Theme.LIGHT} locale="en">
      <GeneralErrorBoundary />
    </SimpleDocument>
  );
};

function AppWithProviders() {
  const data = useLoaderData<typeof loader>();
  const nonce = useNonce();
  const allowIndexing = data.ENV.ALLOW_INDEXING !== "false";

  return (
    <ThemeProvider specifiedTheme={data.theme}>
      <Document
        dataTheme={data.theme}
        nonce={nonce}
        env={data.ENV}
        locale={data.locale}
        allowIndexing={allowIndexing}
      >
        <Outlet />
        <ProgressBar />
      </Document>
    </ThemeProvider>
  );
}

export default withSentry(AppWithProviders);
